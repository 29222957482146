.ant-spin-dot-item {
  background-color: rgba(0, 0, 0, 0.65) !important;
}

.tabs-container > .ant-tabs .ant-tabs-nav {
	width: 100%;
}

.tabs-container > .ant-tabs .ant-tabs-nav > .ant-tabs-ink-bar {
	background-color: rgba(0, 0, 0, 0.65);
}

.tabs-container > .ant-tabs .ant-tabs-nav > div:first-child {
	display: flex;
}

.tabs-container > .ant-tabs .ant-tabs-nav > div:first-child > .ant-tabs-tab {
	flex-grow: 1;
	text-align: center;
	color: rgba(0, 0, 0, 0.65);;
}

.plastchild p:last-child {
	margin-bottom: 0;
}